import * as React from 'react';
import { graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

// Components
import Layout from '../components/layout/Layout';

// Images
import headerBackgroundImage from '../images/svg/components/card-11.svg';

const CookiePolicyPage = ({data}) => {

  const {t} = useTranslation();

  return (
    <Layout 
      pageType="classic" 
      pageTitle={t('meta.title')} 
      pageDescription={t('meta.description', { title: data.site.siteMetadata.title })} 
      pageKeywords={t('meta.keywords')} 
      headerClass="navbar-absolute-top navbar-light" 
      headerStyle="light"
    >
      <div>
        <div className="bg-img-start" style={{backgroundImage: 'url(' + headerBackgroundImage + ')'}}>
          <div className="container content-space-t-3 content-space-t-lg-5 content-space-b-2">
            <div className="w-md-75 w-lg-50 text-center mx-md-auto">
              <h1>{t('title')}</h1>
              {data.cookie_policy &&
                <p>{t('subtitle', { date: data.cookie_policy.frontmatter.date })}</p>
              }
            </div>
          </div>
        </div>
        <div className="container content-space-1 content-space-md-3">
          {data.cookie_policy &&
            <MDXProvider
              components={{
                h2: props => <h2 {...props} className="mt-5 mb-3">{props.children}</h2>,
                h3: props => <h3 {...props} className="mt-5 mb-3">{props.children}</h3>,
                h4: props => <h4 {...props} className="mt-5 mb-3">{props.children}</h4>,
                h5: props => <h5 {...props} className="mt-5 mb-3">{props.children}</h5>,
                a: props => <a {...props} className="text-highlight-warning" target="_blank" rel="noopener noreferrer">{props.children}</a>,
                Link: props => <Link {...props} className="text-highlight-warning">{props.children}</Link>,
              }}
            >
              <MDXRenderer
                site={data.site}
                >
                {data.cookie_policy.body}
              </MDXRenderer>
            </MDXProvider>  
          }
        </div>
      </div>
    </Layout>
  )
}

export default CookiePolicyPage

export const query = graphql`
  query ($language: String!) {
    site: site {
      siteMetadata {
        siteUrl
        title
      }
    }
    locales: allLocale(filter: {ns: {in: ["common", "header", "cookie-policy", "footer"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    cookie_policy: mdx(
        fileAbsolutePath: { regex: "/data/cookie_policy/" }, 
        slug: {eq: $language}
      ) 
      {
      frontmatter {
        active
        date(formatString: "D MMMM YYYY", locale: $language)
      }
      id
      body
      slug
    }
  }
`;
